import { InlineLoader } from '../PageLoader'
import PopupNew, { popupSize } from '../PopupNew'

export const AsyncLoaderPopup = (
  <PopupNew
    title=""
    size={popupSize.SMALL}
    contentText={<InlineLoader />}
    noFooter
    overPopup
  />
)
